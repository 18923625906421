.footer-container {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0 10%;

  h3 {
    text-align: center;
  }

  .footer-grid {
    // 5 grids.
    .footer-logo {
      //logo css
    }
  }

  .footer-private-info {
    font-size: small;
    text-align: center;
  }

  .footer-private-info-container {
    padding: 84px 28px;
  }
}

.social-icons {
  height: 36px;
  margin-right: 8px;
  margin-left: 8px;
}

@media only screen and (max-width: 600px) {
  .footer-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

@media only screen and (max-width: 754px) {
  .footerGridContainer {
    // background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}