.insta-strip-container {
  width: 80vw;
  margin: auto;
  article {
    height: 200px;
    width: 200px;
  }
}

.instagram-feed {
  background-color: #efefef;
  padding-top: 40px;
  padding-bottom: 30px;
  background-image: url(../images/ig-bg-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background: url(../images/ig-bg-img.png) 0% 0% / cover no-repeat
    rgb(239, 239, 239);
}

@media only screen and (max-width: 768px) {
  .instagram-feed {
    padding-top: 20px;
  }
}

.ig-id-logo-container {
  display: flex;
  align-items: flex-end;
  position: relative;
  justify-content: center;
  align-items: center;
  span {
    position: absolute;
    display: flex;

    flex-direction: row;
    align-items: flex-end;
    left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 90px;
    }
  }
}

.joy-at-work-red {
  color: #cc0000;
}

.instagram-image-container {
  height: 256px !important;
  width: 394px !important;
  background-color: white;
}

.insta-id {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  margin-left: 20px;
}

.instagram-image-container-box {
  // height: 256px !important;
  // width: 394px !important;
  width: 320px !important;
  height: 320px !important;
  background-color: white;
}

@media only screen and (max-width: 970px) {
  .ig-id-logo-container {
    display: flex;
    flex-direction: column;
  }

  .ig-id-logo-container {
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: center;
    align-items: center;
    span {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: flex-end;
      left: 0;
      margin-top: 12px;

      justify-content: center;
      align-items: center;
      img {
        height: 90px;
      }
    }
  }
}

.cardmedia-img {
  width: 100%;
  height: 306px;
}
