.department-main-container {
  padding: 0px 4%;
  .department-heading-container {
    text-align: center;
  }
}

.department-viewmore-container {
  margin: 0 auto;
  .view-more-button {
    background-color: white;
    color: red;
    border-radius: 20px;
    width: 140px;
    border: solid 2px red;
  }
}

.resp-grid-card {
  padding: 10px 0px;
  background-color: #d1d2d4;
  text-align: center;
}

.resp-grid-img-container {
  height: 220px;
  img {
    width: 100%;
    height: 100%;
  }
}
