.slider-container {
  width: 80vw;
  margin: 0 auto;
}
.slider-div {
  width: 60vw;

  .card {
    padding: 10px;
    margin: 0 10px;
    height: 70vh;
    display: flex;
    flex-direction: row;
    .card-content {
      background-color: #cc0000;
      width: 70%;
      color: white;
      padding: 20% 10%;
      p {
        color: white;
      }
    }
    .card-img {
      background-color: black;
      width: 30%;
    }
    .inner-div {
      background-color: green;
    }
  }
}

.slick-slide.slick-center img {
  margin: 0 auto;
  height: 80vh;
}

.slick-slide img {
  height: 80vh;
}

.slick-arrow {
  color: red;
  background-color: red;
}

.slick-prev:before {
  color: black !important;
}

.slick-next:before {
  color: black !important;
}
