.ck_heading_heading1,
h1 .ck_heading_heading2,
h2 .ck_heading_heading3,
h3 .ck_heading_heading4,
h4 .ck_heading_heading5,
h5 .ck_heading_heading6,
h6 {
  color: #333333;
}

.ck_heading_heading1,
h1 {
  font-weight: bold;
  font-size: 2.666666666666667rem;
  line-height: 4rem;
  letter-spacing: -0.01562em;
  margin: 0 !important;
}

.ck_heading_heading2,
h2 {
  font-weight: bold;
  font-size: 1.9rem;
  line-height: 3rem;
  letter-spacing: -0.00833em;
  margin: 0 !important;
}

.ck_heading_heading3,
h3 {
  font-weight: bold;
  font-size: 1.872rem;
  line-height: 3rem;
  letter-spacing: 0em;
  margin: 0 !important;
}

.ck_heading_heading4,
h4 {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 3rem;
  letter-spacing: 0.00735em;
  margin: 0 !important;
}

.ck_heading_heading5,
h5 {
  font-weight: bold;
  font-size: 1.35rem;
  line-height: 2.4rem;
  letter-spacing: 0rem;
  margin: 0 !important;
}

.ck_heading_heading6,
h6 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.0075em;
  margin: 0 !important;
}

@media only screen and (max-width: 1279.95px) {
  .ck_heading_heading1,
  h1 {
    font-size: 2.666666666666667rem;
  }

  .ck_heading_heading2,
  h2 {
    font-size: 1.6rem;
  }

  .ck_heading_heading3,
  h3 {
    font-size: 1.5rem;
  }

  .ck_heading_heading4,
  h4 {
    font-size: 1.25rem;
  }

  .ck_heading_heading5,
  h5 {
    font-size: 1.15rem;
  }

  .ck_heading_heading6,
  h6 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 959.95px) {
  .ck_heading_heading1,
  h1 {
    font-size: 2.666666666666667rem;
  }

  .ck_heading_heading2,
  h2 {
    font-size: 1.6rem;
  }

  .ck_heading_heading3,
  h3 {
    font-size: 1.5rem;
  }

  .ck_heading_heading4,
  h4 {
    font-size: 1.25rem;
  }

  .ck_heading_heading5,
  h5 {
    font-size: 1.15rem;
  }

  .ck_heading_heading6,
  h6 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 599.95px) {
  .ck_heading_heading1,
  h1 {
    font-size: 2.666666666666667rem;
    line-height: 2rem;
  }

  .ck_heading_heading2,
  h2 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .ck_heading_heading3,
  h3 {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .ck_heading_heading4,
  h4 {
    font-size: 1.1rem;
  }

  .ck_heading_heading5,
  h5 {
    font-size: 1rem;
  }

  .ck_heading_heading6,
  h6 {
    font-size: 1rem;
  }
}

p {
  font-size: 1rem;
  color: #333333;
}

a {
  color: #CC0000;
  text-decoration: underline;
}

a:hover {
  color: #CC0000 !important;
}
